export const POST_MESSAGE_NAMESPACE = 'tpp';

export const VERSION = '1.2.28';
export const GIT_HASH = '44314b378e54ce933001efce1ca2123bedebcd10';

export const MODULE_ENDPOINT = 'class:TppApi';
export const PREVIEW_ID_ATTRIBUTE = 'data-preview-id';
export const CUSTOM_PREVIEW_ID_PREFIX = 'custom:';

export const Event = {
  // TPP Events
  Initialized: 'tpp-initialized',
  ElementChange: 'tpp-element-change',
  StatusChange: 'tpp-status-change',
  RerenderView: 'tpp-rerender-view',
  DropElement: 'tpp-drop-element',
  // WE Events (piped)
  PreviewRequest: 'TPP_PREVIEW_REQUEST',
  NavigationChange: 'TPP_NAVIGATION_CHANGED',
  WorkflowTransition: 'TPP_WORKFLOW_ACTION',
  // WE Events (custom)
  DragElement: 'TPP_DRAG_ACTION',
  DisplayChangesRequest: 'TPP_DISPLAY_CHANGES_REQUEST',
  ResetDisplayChangesRequest: 'TPP_RESET_DISPLAY_CHANGES_REQUEST',
  // WE Events (MPP)
  TppMppChange: 'TPP_MPP_CHANGE'
};

export const Action = {
  EDIT: 'EDIT',
  EDIT_META_DATA: 'EDIT_META_DATA',
  STATUS: 'STATUS',
  DELETE: 'DELETE',
  DELETE_WORKFLOW: 'DELETE_WORKFLOW',
  RENDER: 'RENDER',
  RENDER_START_NODE: 'RENDER_START_NODE',
  CREATE_PAGE: 'CREATE_PAGE',
  CREATE_SIBLING_SECTION: 'CREATE_SIBLING_SECTION',
  CREATE_CHILD_SECTION: 'CREATE_CHILD_SECTION',
  CREATE_DATASET: 'CREATE_DATASET',
  WORKFLOW_START: 'WORKFLOW_START',
  RELATED_ELEMENTS: 'RELATED_ELEMENTS',
  AFFECTED_ELEMENTS: 'GET_AFFECTED_WORKFLOW_ELEMENTS',
  WORKFLOW_PROCESS: 'WORKFLOW_PROCESS',
  CROP_IMAGE: 'CROP_IMAGE',
  TOGGLE_BOOKMARK: 'TOGGLE_BOOKMARK',
  FSID_TO_PREVIEW_ID: 'FSID_TO_PREVIEW_ID',
  PROJECT_INFO: 'PROJECT_INFO',
  TRANSLATION: 'TRANSLATION',
  TRANSFER_SECTION: 'TRANSFER_SECTION',
  TRANSFER_SECTION_ALLOWED: 'TRANSFER_SECTION_ALLOWED',
  REQUEST_CHANGE_SET: 'REQUEST_CHANGE_SET',
  SHOW_COMPARISON_DIALOG: 'SHOW_COMPARISON_DIALOG',
  SHOW_CUSTOM_DIALOG: 'SHOW_CUSTOM_DIALOG'
};

export class DuplicatePageError extends Error {
	constructor(message, previewId) {
		super(message);
		this.previewId = previewId;
	}

	getPreviewId() {
		return this.previewId;
	}
}